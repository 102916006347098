import {
  Button,
  ButtonProps,
  Icons,
  notification,
  Popconfirm,
  useApiUrl,
} from "@pankod/refine"
import { DeleteOneResponse } from "@pankod/refine/dist/contexts/data/IDataContext"
import { axiosInstance } from "providers"
import React, { useCallback, useEffect, useState } from "react"

export type DDeleteButtonProps = ButtonProps & {
  resourceName: string
  fetchResource: () => void
  recordItemId?: string
  variables: {
    [s: string]: any
  }
  onSuccess?: (value: DeleteOneResponse) => void
  hideText?: boolean
  ignoreAccessControlProvider?: boolean
}

export const DDeleteButton = ({
  resourceName,
  fetchResource,
  onSuccess,
  children,
  variables,
  hideText = false,
  ignoreAccessControlProvider = false,
  ...rest
}: DDeleteButtonProps) => {
  const API_URL = useApiUrl()
  const [isLoading, setIsLoading] = useState(false)
  const [deleteURL, setDeleteURL] = useState("")

  useEffect(() => {
    if (resourceName === "devices") {
      setDeleteURL(`${API_URL}/consumer/DelHotspot`)
    }
    return () => {}
  }, [API_URL, resourceName])

  // ! warning
  // we use the default axiosInstance here
  const onConfirm = useCallback(async () => {
    if (isLoading === true) {
      return
    }
    setIsLoading(true)
    const data = await axiosInstance.post(deleteURL, variables)
    notification.success({
      message: "Success",
      description: "The device has been deleted",
    })
    fetchResource()
    setIsLoading(false)

    return data
  }, [deleteURL, fetchResource, isLoading, variables])

  return (
    <Popconfirm
      key="delete"
      okText="Delete"
      cancelText="Cancel"
      okType="danger"
      title="Are you sure?"
      okButtonProps={{ disabled: isLoading }}
      onConfirm={onConfirm}>
      <Button
        danger
        loading={isLoading}
        icon={<Icons.DeleteOutlined />}
        {...rest}>
        {!hideText && (children ?? "Delete")}
      </Button>
    </Popconfirm>
  )
}
