import {
  Show,
  Typography,
  IResourceComponentsProps,
  TagField,
  Progress,
  Space,
  Card,
  Tooltip,
  Descriptions,
} from "@pankod/refine"
import { IDevice } from "interfaces"
import "./style.less"
import dayjs, { Dayjs } from "dayjs"
import { COLORS, FIXED_DIGITS } from "localConstants"
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip as ChartsToolTip,
  XAxis,
  YAxis,
} from "recharts"
import { DeviceStatus, StatusIcon } from "components"

const { Title, Text } = Typography

interface ILineData {
  name: string
  x: string
  y: number
  time: Dayjs
}
interface IRewardData {
  day: {
    data: Array<ILineData>
    axisTickValues: {
      main: Array<string>
      dependent: Array<string>
    }
    sum: number
  }
  week: {
    data: Array<ILineData>
    axisTickValues: {
      main: Array<string>
      dependent: Array<string>
    }
    sum: number
  }
  month: {
    data: Array<ILineData>
    axisTickValues: {
      main: Array<string>
      dependent: Array<string>
    }
    sum: number
  }
}

export const DeviceShow: React.FC<IResourceComponentsProps> = () => {
  const record: IDevice = JSON.parse(localStorage.getItem("deviceDetail") ?? "")

  const MemoryUsage = () => {
    const memorySize = record.memorySize
    const memoryUsedSize = record.memoryUsedSize
    let percent = 0
    if (memorySize && memoryUsedSize) {
      percent = Math.round((memoryUsedSize / memorySize) * 100)
    }
    const isMoreThanHalf = percent > 50

    return (
      <Space>
        <Progress
          percent={percent}
          steps={10}
          showInfo={false}
          size="small"
          strokeColor={isMoreThanHalf ? "#ffb980" : "#52c41a"}
        />
        <Text>
          <Text type={isMoreThanHalf ? "warning" : "success"}>
            {memoryUsedSize}
          </Text>
          <Text>/{memorySize}</Text>
        </Text>
      </Space>
    )
  }

  const DiskUsage = () => {
    const diskSize = record.diskSize
    const diskUsedSize = record.diskUsedSize
    let percent = 0
    if (diskSize && diskUsedSize) {
      percent = Math.round((diskUsedSize / diskSize) * 100)
    }
    const isMoreThanHalf = percent > 50

    return (
      <Space>
        <Progress
          percent={percent}
          steps={10}
          showInfo={false}
          size="small"
          strokeColor={isMoreThanHalf ? "#ffb980" : "#52c41a"}
        />
        <Text>
          <Text type={isMoreThanHalf ? "warning" : "success"}>
            {diskUsedSize}
          </Text>
          <Text>/{diskSize}</Text>
        </Text>
      </Space>
    )
  }

  const rewardData: IRewardData = {
    day: {
      data: [],
      axisTickValues: {
        main: [],
        dependent: [],
      },
      sum: 0,
    },
    week: {
      data: [],
      axisTickValues: {
        main: [],
        dependent: [],
      },
      sum: 0,
    },
    month: {
      data: [],
      axisTickValues: {
        main: [],
        dependent: [],
      },
      sum: 0,
    },
  }

  record.HotspotRewardLineDay.slice(0, 24).forEach((item) => {
    const time = dayjs(item.timestamp)
    rewardData.day.data.unshift({
      // .format("MMM D HH:mm z")
      x: "" + time.hour(),
      y: item.total,
      time: time,
      name: "" + time.hour(),
    })
    rewardData.day.axisTickValues.main.unshift("" + time.hour())
    rewardData.day.sum = rewardData.day.sum + item.total
  })
  record.HotspotRewardLineWeek.slice(0, 7).forEach((item) => {
    const time = dayjs(item.timestamp)
    rewardData.week.data.unshift({
      // .format("MMM D HH:mm z")
      x: "" + time.format("ddd"),
      y: item.total,
      time: time,
      name: "" + time.format("ddd"),
    })
    rewardData.week.axisTickValues.main.unshift("" + time.format("ddd"))
    rewardData.week.sum = rewardData.week.sum + item.total
  })
  record.HotspotRewardLineMonth.slice(0, 30).forEach((item) => {
    const time = dayjs(item.timestamp)
    rewardData.month.data.unshift({
      x: "" + time.format("MMM D"),
      y: item.total,
      time: time,
      name: "" + time.format("MMM D"),
    })
    rewardData.month.axisTickValues.main.unshift("" + time.format("MMM D"))
    rewardData.month.sum = rewardData.month.sum + item.total
  })

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#333",
            opacity: 0.8,
            color: "#fff",
            padding: "2px 6px",
            borderRadius: "6px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          }}>
          <div>{payload[0].payload.time.format("MMM D HH:mm z")}</div>
          <div className="">{payload[0].value}</div>
        </div>
      )
    }

    return null
  }

  const lineChartProps = {
    margin: { top: 20, right: 20, bottom: 0, left: 0 },
    width: 600,
    height: 140,
  }

  return (
    <Show title={record?.name} pageHeaderProps={{ extra: "" }}>
      <Space direction="vertical">
        <Card>
          <Title level={5}>Serial Number</Title>
          <Text>{record?.minerSn}</Text>

          <Title level={5}>Transmit Scale</Title>
          <Text>{record?.transmitScale.toFixed(FIXED_DIGITS)}</Text>

          <Title level={5}>Remark</Title>
          <Text style={{ wordBreak: "break-all" }}>
            {record?.remark || "-"}
          </Text>
        </Card>

        <Card title={`updated at ${record.recTime} (UTC)`}>
          <Descriptions size="middle" layout="vertical">
            <Descriptions.Item label="Online?">
              <StatusIcon
                label={{
                  yes: "Online",
                  no: "Offline",
                }}
                value={!!record.status?.online}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Relayed?">
              <StatusIcon
                label={{
                  yes: "Not Relayed",
                  no: "Relayed",
                }}
                value={!record.status?.relayed}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Synced?">
              <StatusIcon
                label={{
                  yes: "Synced",
                  no: "Not Synced",
                }}
                value={!!record.status?.synced}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Miner Firmware Version">
              {record?.version}
            </Descriptions.Item>
            <Descriptions.Item label="Memory Usage">
              <MemoryUsage />
            </Descriptions.Item>
            <Descriptions.Item label="Disk Usage">
              <DiskUsage />
            </Descriptions.Item>
            <Descriptions.Item label="Internal IP">
              {record?.internalIP}
            </Descriptions.Item>
            <Descriptions.Item label="External IP">
              {record?.externalIP}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <>
                  <Tooltip title="Device Sync Height">
                    <Text style={{ cursor: "help" }}>Miner Height</Text>
                  </Tooltip>
                  /
                  <Tooltip title="Network Live Height">
                    <Text style={{ cursor: "help" }}>Live Height</Text>
                  </Tooltip>
                </>
              }>
              <Text mark>{record?.minerHeight}</Text>/
              <Text>{record?.liveHeight}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Device Temperature">
              {record?.devTemperature} °C
            </Descriptions.Item>
          </Descriptions>
        </Card>

        <Card title="Rewards">
          <div className="chart-container">
            <Space>
              <Text strong>Last 24h</Text>
              <Text italic type="secondary">
                {rewardData.day.sum.toFixed(FIXED_DIGITS)} in total
              </Text>
            </Space>
            <LineChart data={rewardData.day.data} {...lineChartProps}>
              <Line
                strokeWidth={2}
                dot={false}
                type="monotone"
                dataKey="y"
                stroke="#227D51"
              />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="5 5"></CartesianGrid>
              <XAxis dataKey="name" interval={3}></XAxis>
              <YAxis />
              <ChartsToolTip content={<CustomTooltip />} />
            </LineChart>
          </div>
          <div className="chart-container">
            <Space>
              <Text strong>Last Week</Text>
              <Text italic type="secondary">
                {rewardData.week.sum.toFixed(FIXED_DIGITS)} in total
              </Text>
            </Space>
            <LineChart data={rewardData.week.data} {...lineChartProps}>
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="y"
                stroke="#227D51"
                dot={false}
              />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="5 5"></CartesianGrid>
              <XAxis dataKey="name"></XAxis>
              <YAxis />
              <ChartsToolTip content={<CustomTooltip />} />
            </LineChart>
          </div>
          <div className="chart-container">
            <Space>
              <Text strong>Last 30d</Text>
              <Text italic type="secondary">
                {rewardData.month.sum.toFixed(FIXED_DIGITS)} in total
              </Text>
            </Space>
            <LineChart data={rewardData.month.data} {...lineChartProps}>
              <Line
                dot={false}
                strokeWidth={2}
                type="monotone"
                dataKey="y"
                stroke="#227D51"
              />
              <CartesianGrid
                stroke="#ccc"
                strokeDasharray="5 5"></CartesianGrid>
              <XAxis dataKey="name" interval={5}></XAxis>
              <YAxis />
              <ChartsToolTip content={<CustomTooltip />} />
            </LineChart>
          </div>
        </Card>
      </Space>
    </Show>
  )
}
