import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  Space,
  ShowButton,
  useTranslate,
  useNavigation,
  Modal,
  useApiUrl,
  notification,
  Tooltip,
  Form,
  Select,
  Button,
  HttpError,
  Typography,
} from "@pankod/refine"
import Link from "antd/lib/typography/Link"
import TextArea from "antd/lib/input/TextArea"
import { DDeleteButton, StatusIcon } from "components"
import { IDevice, IDeviceFilterVariables } from "interfaces"
import { useCallback, useState } from "react"
import { axiosInstance } from "providers"

const { Text } = Typography

export const DeviceList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    IDevice,
    HttpError,
    IDeviceFilterVariables
  >({
    // * this would query the api again, but we don't need it
    // onSearch: (params) => {
    //   const filters: CrudFilters = []
    //   const { status } = params
    //   console.log(params)
    //   filters.push({
    //     field: "status",
    //     operator: "contains",
    //     value: {
    //       [status]: true
    //     },
    //   })
    //   return filters
    // },
  })
  // console.log("form props", searchFormProps)

  const [filters, setFilters] = useState<IDeviceFilterVariables | null>(null)

  searchFormProps.onFinish = (values) => {
    console.log("values", values)

    setFilters(values)
  }

  const { show } = useNavigation()

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null)
  const [remarkModalSwitch, setRemarkModalSwitch] = useState(false)
  const [remark, setRemark] = useState("")

  const API_URL = useApiUrl()

  const [isLoading, setIsLoading] = useState(false)

  const onOK = useCallback(async () => {
    if (isLoading === true) {
      return
    }
    setIsLoading(true)
    const data = await axiosInstance.post(
      `${API_URL}/consumer/SetHotspotRemark`,
      { sn: selectedDevice?.minerSn, data: remark }
    )
    notification.success({
      message: "Success",
      description: "Remark updated",
    })
    tableQueryResult.refetch()
    setIsLoading(false)
    setRemarkModalSwitch(false)
    setSelectedDevice(null)

    return data
  }, [API_URL, isLoading, remark, selectedDevice?.minerSn, tableQueryResult])

  tableProps.dataSource?.forEach((resource) => {
    // to format data

    // name
    // if not data, show default string in name
    if (resource.heliumData?.name) {
      resource.name = resource.heliumData.name
        .split("-")
        .map((i) => {
          return `${i[0].toUpperCase()}${i.slice(1)}`
        })
        .join(" ")
    } else {
      resource.name = "Data will update after several minutes"
    }

    // skip if no data
    if (!resource.heliumData) {
      return
    }

    // helium data
    // status
    let status = {
      online: false,
      OTA: false,
      synced: false,
      relayed: false,
    }
    if (resource.heliumData.status.online === "online") {
      status.online = true
    }
    status.synced = resource.HotspotStatus.synced
    status.relayed = resource.HotspottyApi.etl_information.relayed
    resource.transmitScale = resource.heliumData.reward_scale || 0
    resource.liveHeight = `${resource.heliumData.block}` || "-"

    // kafka
    if (!!resource.kafka) {
      status.OTA = true
      // receive time(from device)
      resource.recTime = resource.kafka.recTime
      // version
      resource.version = resource.kafka.ver
      // memory
      resource.memorySize = resource.kafka.memSize
      resource.memoryUsedSize = resource.kafka.memUsedSize
      // disk
      resource.diskSize = resource.kafka.dbSize
      resource.diskUsedSize = resource.kafka.dbUsedSize
      // IP address
      resource.internalIP = resource.kafka.ip1
      resource.externalIP = resource.kafka.ip2
      // block height
      resource.minerHeight = resource.kafka.devSyncHigh || "-"
      resource.devTemperature = resource.kafka.devTmp || 0
    }
    resource.status = status
  })
  if (filters) {
    if (filters.status) {
      tableProps.dataSource = tableProps.dataSource?.filter((resource) => {
        if (resource.status) {
          return resource?.status[filters.status]
        }

        return true
      })
    }
  }

  const onClickShowButton = (record: IDevice) => {
    // if no kafka, warning and return
    if (!record.kafka) {
      notification.warning({
        message: "Warning",
        description: (
          <>
            <p>Need Over The Air to get data,</p>
            <p>
              <span>
                try{" "}
                <a
                  href="https://docs.piscesminer.com/pisces-document/firmware-api/webshellcontroller-closed/high-frequency-use-instructions-collection#ota-service-fix-command"
                  target="_blank"
                  rel="noreferrer">
                  this
                </a>{" "}
                to fix
              </span>
            </p>
          </>
        ),
      })
      return
    }
    // actually you should get deviceDetail from API
    // if you want to share the url of a deviceDetail
    localStorage.setItem("deviceDetail", JSON.stringify(record))
    show("devices", record.minerSn)
  }

  //   const categoryIds =
  //     tableProps?.dataSource?.map((item) => item.category.id) ?? []
  //   const { data: categoriesData, isLoading } = useMany<ICategory>({
  //     resource: "categories",
  //     ids: categoryIds,
  //     queryOptions: {
  //       enabled: categoryIds.length > 0,
  //     },
  //   })

  //   const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //     resource: "categories",
  //   })

  const statusSelectProps = {
    allowClear: true,
    children: ["relayed", "online", "synced"].map((item) => {
      return <Select.Option value={item}>{item}</Select.Option>
    }),
    placeholder: "Device Status",
  }

  return (
    <>
      <Form layout="inline" {...searchFormProps} style={{ marginBottom: 18 }}>
        <Form.Item label="status" name="status">
          <Select {...statusSelectProps} />
        </Form.Item>
        <Form.Item>
          <Button size="middle" htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
      </Form>

      <List title={t("deviceList.title")}>
        <Table {...tableProps} rowKey="minerSn">
          <Table.Column
            dataIndex="minerSn"
            key="minerSn"
            title="Serial Number"
            render={(value, record: IDevice) => (
              <Link onClick={() => onClickShowButton(record)}>{value}</Link>
            )}
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value, record: IDevice) => (
              <Link onClick={() => onClickShowButton(record)}>{value}</Link>
            )}
          />
          <Table.Column
            dataIndex={["status", "online"]}
            key="online"
            title={
              <Tooltip title="Online?">
                <Text style={{ cursor: "help" }}>O</Text>
              </Tooltip>
            }
            render={(value) => {
              return (
                <StatusIcon
                  label={{
                    yes: "Online",
                    no: "Offline",
                  }}
                  value={value}
                />
              )
            }}
          />
          <Table.Column
            dataIndex={["status", "relayed"]}
            key="relayed"
            title={
              <Tooltip title="Relayed?">
                <Text style={{ cursor: "help" }}>R</Text>
              </Tooltip>
            }
            render={(value) => {
              return (
                <StatusIcon
                  label={{
                    yes: "Not Relayed",
                    no: "Relayed",
                  }}
                  value={!value}
                />
              )
            }}
          />
          <Table.Column
            dataIndex={["status", "synced"]}
            key="synced"
            title={
              <Tooltip title="Synced?">
                <Text style={{ cursor: "help" }}>S</Text>
              </Tooltip>
            }
            render={(value) => {
              return (
                <StatusIcon
                  label={{
                    yes: "Synced",
                    no: "Not Synced",
                  }}
                  value={value}
                />
              )
            }}
          />
          <Table.Column
            dataIndex="remark"
            key="remark"
            title="Remark"
            render={(value, record: IDevice) => (
              <Link
                style={{ wordBreak: "break-all" }}
                onClick={() => {
                  if (record.remark) {
                    setRemark(record.remark)
                  }
                  setSelectedDevice(record)
                  setRemarkModalSwitch(true)
                }}>
                {value || "Click to edit"}
              </Link>
            )}
          />
          {/* <Table.Column
          dataIndex={["category", "id"]}
          title={t("posts.fields.category.title")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.title
                }
              />
            )
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
        /> */}
          <Table.Column<IDevice>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.minerSn}
                  onClick={() => onClickShowButton(record)}
                />
                <DDeleteButton
                  resourceName="devices"
                  hideText
                  size="small"
                  variables={{
                    sn: record.minerSn,
                    key: record.minerKey,
                  }}
                  fetchResource={tableQueryResult.refetch}
                />
              </Space>
            )}
          />
        </Table>

        <Modal
          title={selectedDevice?.name}
          visible={remarkModalSwitch}
          onCancel={() => {
            setRemarkModalSwitch(false)
            setSelectedDevice(null)
          }}
          onOk={onOK}>
          <TextArea
            showCount
            maxLength={255}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}></TextArea>
        </Modal>
      </List>
    </>
  )
}
