import { AuthProvider } from "@pankod/refine"
import { API_URL, TOKEN_KEY } from "localConstants"
import { AuthHelper, axiosInstance } from "providers"

const authHelper = AuthHelper(API_URL)

export const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const { data, code } = await authHelper.login(
            username,
            password,
        )

        if (code === 200) {
            localStorage.setItem(TOKEN_KEY, data.token)

            // set header axios instance
            axiosInstance.defaults.headers.common = {
                Authorization: `Bearer ${data.token}`,
            }

            return Promise.resolve()
        }
        return Promise.reject()
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY)
        return Promise.resolve()
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY)
        if (token) {
            axiosInstance.defaults.headers.common = {
                Authorization: `Bearer ${token}`,
            }
            return Promise.resolve()
        }

        return Promise.reject()
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY)
        if (!token) {
            return Promise.reject()
        }

        // const { data, status } = await strapiAuthHelper.me(token)
        // if (status === 200) {
        //     const { id, username, email } = data
        //     return Promise.resolve({
        //         id,
        //         username,
        //         email,
        //     })
        // }

        return Promise.reject()
    },
}