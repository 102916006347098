import JSEncrypt from 'jsencrypt'
import { PUBLIC_KEY } from 'localConstants'
import DOMPurify from 'dompurify'
import { DompurifyConfig } from 'interfaces'

export const rsaEncrypt = (input: string) => {
    let encryption = new JSEncrypt()
    encryption.setPublicKey(PUBLIC_KEY)
    let result = encryption.encrypt(input)
    return result
}
export function sanitize(source: string, theConfig: DompurifyConfig = {
    USE_PROFILES: { html: true }
}) {
    return DOMPurify.sanitize(source, theConfig).toString()
}

