import { Icons, Refine } from "@pankod/refine"
import routerProvider from "@pankod/refine-react-router"

import "styles/antd.less"
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout"
import { useTranslation } from "react-i18next"
import { authProvider, DataProvider } from "providers"
import { DeviceCreate, DeviceList, DeviceShow } from "pages/devices"
import { DashboardPage } from "pages/dashboard"
import { Login } from "pages/login"
import { API_URL } from "localConstants"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import "dayjs/locale/en"
import { useEffect } from "react"

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale("en")

function App() {
  const { t, i18n } = useTranslation()

  // useTheme theme setTheme
  // initialize theme to 'light
  useEffect(() => {
    // set default theme to light
    const theme = localStorage.getItem("theme")
    if (theme) {
      document.documentElement.dataset.theme = theme
    } else {
      localStorage.setItem("theme", "light")
      document.documentElement.dataset.theme = "light"
    }

    return () => {}
  }, [])

  // the old url "https://pisces-dashboard.sidcloud.cn/api/"
  const dataProvider = DataProvider(API_URL)
  // ! the reverse proxy is buggy, so we need to use the real url
  // * there is a reverse proxy on the server so
  // * only add '/api' part to the url
  // const dataProvider = DataProvider("/api")

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  return (
    <Refine
      routerProvider={routerProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      DashboardPage={DashboardPage}
      LoginPage={Login}
      resources={[
        {
          name: "devices",
          options: {
            label: "Devices",
            route: "devices",
          },
          icon: <Icons.DatabaseOutlined />,
          list: DeviceList,
          show: DeviceShow,
          create: DeviceCreate,
        },
      ]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}></Refine>
  )
}

export default App
