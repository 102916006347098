import { Row } from "@pankod/refine"
import "./style.less"

interface Props {
  label: string
  value?: string
}

export const Item = (props: Props) => {
  const { label, value } = props

  return (
    <Row justify="center" className="rewards__item">
      <div className="label">{label}</div>
      <div className="value">{value ?? "--"}</div>
    </Row>
  )
}
