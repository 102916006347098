import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Row,
  Tooltip,
  Typography,
  useLogin,
} from "@pankod/refine"
import { SafeInput } from "components"
import { SING_UP_URL } from "localConstants"
import React from "react"

interface Props {}

interface ILoginForm {
  username: string
  password: string
}

export const Login = (props: Props) => {
  const [form] = Form.useForm<ILoginForm>()
  const { mutate: login } = useLogin<ILoginForm>()
  const { Text, Title, Link } = Typography

  const MainTitle = () => (
    <Title level={3} className="layout-title">
      <Text>
        Use your{" "}
        <Tooltip title="no account? sign up here">
          <Link href={SING_UP_URL} target="_blank">
            pisces account
          </Link>
        </Tooltip>{" "}
        to login
      </Text>
    </Title>
  )

  return (
    <div>
      <AntdLayout>
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}>
          <Col>
            <MainTitle />
            <Card>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values)
                }}
                requiredMark={false}>
                <Form.Item
                  name="username"
                  label={"Username"}
                  rules={[{ required: true }]}>
                  <SafeInput size="large" placeholder="your@email.address" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={"Password"}
                  rules={[{ required: true }]}>
                  <SafeInput
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>

                <Button type="primary" size="large" htmlType="submit" block>
                  Sign in
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </AntdLayout>
    </div>
  )
}
