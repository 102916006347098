import { axiosInstance } from "providers"
import { rsaEncrypt } from "utils"

interface ILoginResponse {
    code: number
    data: IUser
}

interface IRole {
    id: number | string
    name: string
    description: string
    type: string
}

interface IUser {

    email: string
    roles: string
    token: string

    // id: number | string
    // username: string
    // email: string
    // provider: string
    // confirmed: boolean
    // blocked: boolean
    // role: IRole
    // created_at: string
    // updated_at: string
}

export const AuthHelper = (apiUrl: string = '') => ({
    login: async (principal: string, credentials: string) => {
        const url = `${apiUrl}/auth/login`

        return (await axiosInstance.post<ILoginResponse>(url, {
            principal: rsaEncrypt(principal),
            credentials: rsaEncrypt(credentials)
        }))?.data
    },
    me: async (token: string) => {
        return await axiosInstance.get<IUser>(`${apiUrl}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    },
})