import React from "react"
import routerProvider from "@pankod/refine-react-router"
import { TitleProps } from "@pankod/refine"

const { Link } = routerProvider

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/images/pisces logo(无文字).svg"}
        alt="Pisces"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 24px",
          height: "60px",
        }}
      />
    ) : (
      <img
        src={"/images/zyf8210075-双鱼座矿机-矿机3.png"}
        alt="Pisces"
        style={{
          width: "200px",
          height: "60px",
          padding: "12px 24px",
        }}
      />
    )}
  </Link>
)
