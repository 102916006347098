import { Col, Icons, Row, useTranslate } from "@pankod/refine"
import { DCard } from "components/layout/dCard"
import { Item } from "./item"

interface Props {
  last24h: string
  last7d: string
  last30d: string
  total: string
}

export const Rewards = ({ last24h, last7d, last30d, total }: Props) => {
  const t = useTranslate()

  return (
    <DCard
      titleIcon={<Icons.LikeOutlined />}
      titleText={t("dashboard.rewards.title")}
      className="rewards">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item label={t("dashboard.rewards.last24h")} value={last24h}></Item>
        </Col>
        <Col span={12}>
          <Item label={t("dashboard.rewards.last7d")} value={last7d}></Item>
        </Col>
        <Col span={12}>
          <Item label={t("dashboard.rewards.last30d")} value={last30d}></Item>
        </Col>
        <Col span={12}>
          <Item label={t("dashboard.rewards.total")} value={total}></Item>
        </Col>
      </Row>
    </DCard>
  )
}
