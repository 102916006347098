// to keep the localStorage token key same as the old project
export const TOKEN_KEY = '__ptoken'
// the RSA public key
export const PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYm/KYS2fkjLlOyD1holEC5EbM1ZWLr1yHqxZ1KLFA SYC/wPKOVILXXDhS8PKuaI+cDLcRTDO02G38qqUSqG9FZ580E+rir1NHYa+zPAVJa/REzBHVY9TwAjWe V1gkxvo/1ystkEei7AwswOVgcGTniLQ8HyLQ2ieo0ZpBBYzRFwIDAQAB'

// the API url
let api_url
if (process.env.REACT_APP_ENV === 'production') {
    api_url = process.env.REACT_APP_API_URL_PROD
} else {
    api_url = process.env.REACT_APP_API_URL_BETA
}
export const API_URL = api_url

// the sign up url
let sign_up_url
if (process.env.REACT_APP_ENV === 'production') {
    sign_up_url = process.env.REACT_APP_SIGN_UP_PROD
} else {
    sign_up_url = process.env.REACT_APP_SIGN_UP_BETA
}
export const SING_UP_URL = sign_up_url

export const COLORS = {
    success: "#4f9c15",
    warning: "#f5a623",
    default: "#9c9a97",
}

export const FIXED_DIGITS = 7