import {
    DataProvider as IDataProvider,
    CrudFilters,
    CrudSorting,
} from "@pankod/refine"
import { AxiosInstance } from "axios"
import { axiosInstance } from "providers"
import { stringify } from "query-string"

const generateSort = (sort?: CrudSorting) => {
    const _sort: string[] = []

    if (sort) {
        sort.map((item) => {
            if (item.order) {
                _sort.push(`${item.field}:${item.order}`)
            }
        })
    }

    return _sort
}

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {}
    if (filters) {
        filters.map(({ field, operator, value }) => {
            if (operator === "eq") {
                queryFilters[`${field}`] = value
            } else {
                queryFilters[`${field}_${operator}`] = value
            }
        })
    }

    return queryFilters
}

export const DataProvider = (
    apiUrl: string = '',
    httpClient: AxiosInstance = axiosInstance,
): IDataProvider => ({
    getList: async ({ resource, pagination, filters, sort }) => {

        // save value from resource as type
        let type = resource

        if (type === 'devices') {
            resource = 'consumer/UserReadDeviceList'
        }

        const url = `${apiUrl}/${resource}`

        const current = pagination?.current || 1
        const pageSize = pagination?.pageSize || 9999

        const _sort = generateSort(sort)
        const queryFilters = generateFilter(filters)

        const query = {
            pageNumber: (current - 1) * pageSize,
            pageSize: pageSize,
            _sort: _sort.length > 0 ? _sort.join(",") : undefined,
        }

        const response = await httpClient.get(
            `${url}?${stringify(query)}&${stringify(queryFilters)}`,
        )

        // due to the wrapper of the API
        // the response is wrapped in a data object
        // the total is in the data.total

        if (type === 'devices') {
            return {
                data: response.data.data.device,
                total: response.data.data.total,
            }
        }

        // if there are special case 
        // add if condition here

        // !
        // this is a fallback
        return {
            data: response.data.data,
            total: response.data.data.total,
        }

    },

    getMany: async ({ resource, ids }) => {
        const url = `${apiUrl}/${resource}`

        const query = ids.map((item: string) => `id_in=${item}`).join("&")

        const { data } = await httpClient.get(`${url}?${query}`)

        return {
            data,
        }
    },

    create: async ({ resource, variables }) => {

        // save value from resource as type
        let type = resource

        if (type === 'devices') {
            resource = 'consumer/AddHotspot'
        }

        const url = `${apiUrl}/${resource}`

        const { data } = await httpClient.post(url, variables)

        return {
            data,
        }
    },

    update: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`

        const { data } = await httpClient.put(url, variables)

        return {
            data,
        }
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.put(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                )
                return data
            }),
        )

        return { data: response }
    },

    createMany: async () => {
        throw new Error("createMany not implemented")
    },

    getOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`

        const { data } = await httpClient.get(url)

        return {
            data,
        }
    },
    deleteOne: {} as any,
    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                )
                return data
            }),
        )
        return { data: response }
    },

    getApiUrl: () => {
        return apiUrl
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`

        if (sort) {
            const sortQuery = generateSort(sort)
            if (sortQuery.length > 0) {
                requestUrl = `${requestUrl}&${stringify({
                    _sort: sortQuery.join(","),
                })}`
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters)
            requestUrl = `${requestUrl}&${stringify(filterQuery)}`
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            }
        }

        let axiosResponse
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload)
                break
            case "delete":
                axiosResponse = await httpClient.delete(url)
                break
            default:
                axiosResponse = await httpClient.get(requestUrl)
                break
        }

        const { data } = axiosResponse

        return Promise.resolve({ data })
    },
})