import { Row, Col, useCustom, useApiUrl, Spin, Card } from "@pankod/refine"
import { EquipmentStatus } from "components"
import { Rewards } from "components/dashboard/rewards"
import { FIXED_DIGITS } from "localConstants"

export const DashboardPage: React.FC = () => {
  const API_URL = useApiUrl()
  const { data, isLoading } = useCustom<{
    data: {
      BasicNum: number
      Relay: number
      Synced: number
      OnlineNumber: number
      RewardAvg: number
      RewardAvgWeek: number
      // 就是说，total是指收入，没有修饰的就是日收益
      RewardTotal: number
      // week是7天
      RewardTotalWeek: number
      // month是30天
      RewardTotalMonth: number
      // total是总计
      RewardTotalTotal: number
    }
  }>({
    url: `${API_URL}/consumer/UserAnalyze`,
    method: "get",
  })

  const statusData = {
    total: `${data?.data.data.BasicNum}`,
    online: `${data?.data.data.OnlineNumber}`,
    synced: `${data?.data.data.Synced}`,
    relayed: `${data?.data.data.Relay}`,
  }

  const rewardsData = {
    last24h: `${data?.data.data.RewardTotal.toFixed(FIXED_DIGITS)}`,
    last7d: `${data?.data.data.RewardTotalWeek.toFixed(FIXED_DIGITS)}`,
    last30d: `${data?.data.data.RewardTotalMonth.toFixed(FIXED_DIGITS)}`,
    total: `${data?.data.data.RewardTotalTotal.toFixed(FIXED_DIGITS)}`,
  }

  return (
    <>
      {isLoading ? (
        <Card loading></Card>
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <EquipmentStatus {...statusData}></EquipmentStatus>
          </Col>
          <Col span={12}>
            <Rewards {...rewardsData}></Rewards>
          </Col>
        </Row>
      )}
    </>
  )
}
