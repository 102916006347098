import { Card, CardProps, Row } from "@pankod/refine"
import { ReactNode } from "react"
import "./style.less"

interface Props extends CardProps {
  titleText?: string
  titleIcon?: ReactNode
}

export const DCard = (props: Props) => {
  const { titleText, titleIcon, children, ...rest } = props

  const titleNode = (
    <Row style={{ fontSize: 22 }} align="middle">
      {titleIcon}
      <div className="" style={{ paddingRight: 8 }}></div>
      {titleText}
    </Row>
  )
  return (
    <Card
      title={titleNode}
      bodyStyle={{
        minHeight: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "default",
      }}
      hoverable
      {...rest}>
      {/* add a wrapper to make it center and spread full width */}
      <div className="" style={{ flex: 1 }}>
        {children}
      </div>
    </Card>
  )
}
