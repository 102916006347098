import { useCallback, useState } from "react"
import {
  Create,
  Form,
  IResourceComponentsProps,
  useForm,
  useCreate,
  Typography,
} from "@pankod/refine"
import { SafeInput } from "components"

interface FormData {
  SN: string
  key: string
}

export const DeviceCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm()
  const [formData, setFormData] = useState<FormData>()

  const { mutate } = useCreate()

  formProps.onFinish = useCallback(() => {
    console.log("form data", formData)

    mutate({
      resource: "devices",
      values: {
        sn: formData?.SN.trim(),
        key: formData?.key.trim(),
      },
    })
  }, [formData, mutate])

  // to get value from Form
  // https://github.com/ant-design/ant-design/issues/21829#issuecomment-850797726

  const { Text } = Typography

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(_, value) => setFormData(value as FormData)}>
        <Form.Item
          tooltip={<Text>on the plate of your device</Text>}
          label="Serial Number"
          name="SN"
          rules={[
            {
              required: true,
            },
          ]}>
          <SafeInput />
        </Form.Item>
        <Form.Item
          tooltip={
            <Text>
              you can find the key in helium APP, "Copy Hotspot Address"
            </Text>
          }
          label="key"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}>
          <SafeInput />
        </Form.Item>
        {/* <Form.Item
          label="remark"
          name="remark"
          rules={[
            {
              required: false,
            },
          ]}>
          <Input />
        </Form.Item> */}
        {/* <Form.Item
          label={t("posts.fields.status.title")}
          name="status"
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            options={[
              {
                label: t("posts.fields.status.published"),
                value: "published",
              },
              {
                label: t("posts.fields.status.draft"),
                value: "draft",
              },
              {
                label: t("posts.fields.status.rejected"),
                value: "rejected",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={t("posts.fields.category.title")}
          name={["category", "id"]}
          rules={[
            {
              required: true,
            },
          ]}>
          <Select {...categorySelectProps} />
        </Form.Item>
        <Form.Item
          label={t("posts.fields.content")}
          name="content"
          rules={[
            {
              required: true,
            },
          ]}>
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item> */}
      </Form>
    </Create>
  )
}
