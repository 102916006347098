import { Col, Progress, Row, Typography } from "@pankod/refine"

// typescript also has "BaseType"
// ! be cautious
import type { BaseType } from "antd/lib/typography/Base"

import { useCallback } from "react"

interface Props {
  title: string
  value: string
  max: string
}

export const Item = (props: Props) => {
  const { Text } = Typography

  const value = parseInt(props.value)
  const max = parseInt(props.max)
  const percent = Math.round((value / max) * 100)

  const progressContent = useCallback(() => {
    let type: BaseType = "secondary"
    if (percent === 100) {
      type = "success"
    }
    return <Text type={type}>{isNaN(percent) ? "-" : percent}%</Text>
  }, [percent, Text])

  return (
    <div className="equipment-status__item">
      <Row justify="center">
        <Progress
          type="circle"
          percent={percent}
          width={80}
          format={progressContent}
        />
      </Row>
      <div className="" style={{ paddingTop: 8 }}></div>
      <Row justify="center">
        <Text>{props.title}:</Text>
        <Text>{value}/</Text>
        <Text>{max}</Text>
      </Row>
    </div>
  )
}
