import { Col, Icons, Row, useTranslate } from "@pankod/refine"
import { DCard } from "components/layout/dCard"
import { ReactNode } from "react"
import { Item } from "./item"
import "./style.less"

interface Props {
  total: string
  online: string
  synced: string
  relayed: string
  children?: ReactNode
}

export const EquipmentStatus = ({ total, online, synced, relayed }: Props) => {
  const t = useTranslate()
  return (
    <DCard
      titleIcon={<Icons.ApiOutlined />}
      titleText={t("dashboard.equipmentStatus.title")}
      className="equipment-status">
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Item title="Online" value={online} max={total}></Item>
        </Col>
        <Col span={8}>
          <Item title="Synced" value={synced} max={total}></Item>
        </Col>
        <Col span={8}>
          <Item title="Relayed" value={relayed} max={total}></Item>
        </Col>
      </Row>
    </DCard>
  )
}
