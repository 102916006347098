import { Icons, Tooltip } from "@pankod/refine"
import { COLORS } from "localConstants"

interface Props {
  value: boolean
  label: {
    yes: string
    no: string
  }
}

export const StatusIcon = ({ value, label }: Props) => {
  return (
    <>
      <Tooltip title={value ? label.yes : label.no}>
        {value ? (
          <Icons.SmileFilled
            style={{
              color: COLORS.success,
              fontSize: "18px",
              lineHeight: "18px",
              cursor: "help",
            }}
          />
        ) : (
          <Icons.FrownFilled
            style={{
              color: COLORS.warning,
              fontSize: "18px",
              lineHeight: "18px",
              cursor: "help",
            }}
          />
        )}
      </Tooltip>
    </>
  )
}
